import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    padding: 64px 24px;
  }

  @media (min-width: 1024px) {
    padding: 64px 80px;
  }
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  max-width: 1024px;

  @media (max-width: 1023px) {
    flex-direction: column;
    row-gap: 72px;
  }

  @media (min-width: 1024px) {
    column-gap: 120px;
  }
`;

export const TopItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  @media (min-width: 1024px) {
    &.--first {
      width: 300px;
    }

    &.--second {
      flex-grow: 1;
    }
  }

  h1 {
    width: fit-content;
  }
`;

export const InputField = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  width: 100%;
`;

export const Input = styled.input`
  flex-grow: 1;
  height: 40px;
  padding: 0 16px;
  color: #fff;
  background-color: transparent;
  font-family: inherit;
  font-size: 13px;
  letter-spacing: 0.13px;
  border: 1px solid #595a5b;
  outline: none;
  transition: all 0.3s;

  &::placeholder {
    color: #595a5b;
  }

  &:hover,
  &:focus {
    border: 1px solid #fff;
  }
`;

export const Options = styled.div`
  display: flex;
  column-gap: 32px;
`;
