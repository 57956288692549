import styled from 'styled-components';

export const Root = styled.li`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    padding-top: 24px;
  }

  &:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid #fff;
  }
`;

export const Desc = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 24px;
  color: #fff;
  font-family: 'Evanston Alehouse 1826 Light';
  font-size: 22px;
  font-weight: 300;
  text-transform: uppercase;
  cursor: pointer;
`;

export const Location = styled.div`
  display: flex;
  flex-direction: column;
  color: #adadad;
  font-size: 13px;
  letter-spacing: 0.13px;
  line-height: 20px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-top: 32px;
  padding-bottom: 16px;
`;

export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding-top: 16px;
  color: #d9d9d9;
  font-family: 'Evanston Alehouse 1826 Light';
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  border-top: 1px solid #373939;

  svg {
    position: relative;
    margin-top: -3px;
    font-size: 20px;
  }
`;

export const Products = styled.div`
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const Product = styled.a`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  width: 140px;
  color: #fff !important;
  font-family: 'Evanston Alehouse 1826 Light';
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  text-align: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 96px;
  object-fit: contain;
`;
